import theme from '@/styles/muiTheme';
import { SG_EVENT_NAMES } from '@/utils/tools/segment/eventNames';
import { trackEvent } from '@/utils/tools/segment/tracking';
import { Alert, CircularProgress, Divider, TextField, Typography } from '@mui/material';
import { captureException } from '@sentry/nextjs';
import { Button, Carousel, Space } from 'antd/lib';
import { signIn } from 'next-auth/react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useRef, useState } from 'react';
import useSWR from 'swr';
import GradinetContainer from '../../layouts/gradinet-container/GradinetContainer';
import PasswordInput from '../password-input/PasswordInput';
import styles from './LoginForm.module.css';

async function createUser(email, password) {
   const res = await fetch('/api/auth/signup', {
      method: 'POST',
      body: JSON.stringify({ email, password }),
      headers: {
         'Content-Type': 'application/json',
      },
   });
   const data = await res.json();
   // check if response successful
   if (!res.ok) {
      throw new Error(data.message || 'Something went wrong!');
   }

   return data;
}

function LoginForm({ login }) {
   const clientReviews = useSWR(`${process.env.NEXT_PUBLIC_PREMAST_API_URL}/reviews`);
   const [errorMessage, setErrorMessage] = useState(null);
   const [loading, setLoading] = useState(false);
   const emailRef = useRef();
   const passwordRef = useRef();
   const confirmPasswordRef = useRef();
   const router = useRouter();
   const { redirect } = router.query;

   // it can be sperated into two functions login and signup in the if statment
   async function handleFormSubmit(e) {
      setLoading(true);
      e.preventDefault();
      setErrorMessage(null);
      const enteredEmail = emailRef.current.value;
      const enteredPassword = passwordRef.current.value;

      // check if the form is login form or signup form
      if (login) {
         const res = await signIn('credentials', {
            redirect: false,
            email: enteredEmail,
            password: enteredPassword,
         });
         if (!res.ok) {
            setErrorMessage(res.error);
            setLoading(false);
            trackEvent(SG_EVENT_NAMES.USER_SIGN_IN_FAILED, { email: enteredEmail, error: res.error });
         } else {
            trackEvent(SG_EVENT_NAMES.USER_SIGN_IN, { email: enteredEmail });

            const { redirect, ...remainingQuery } = router.query;

            if (router.query.ai_assistant) {
               localStorage.setItem('ai_assistant', 'true');
            }

            // Determine the destination URL
            const destination = redirect && redirect !== 'undefined' ? redirect : '/dashboard';

            const queryString = new URLSearchParams(remainingQuery).toString();
            const fullDestination = queryString ? `${destination}?${queryString}` : destination;

            router.push(fullDestination);
         }
      }

      if (!login) {
         const enteredConfirmPassword = confirmPasswordRef.current.value;
         // check if password and confirm password match
         if (enteredPassword !== enteredConfirmPassword) {
            setErrorMessage('Passwords do not match!');
            setLoading(false);
            return;
         }
         try {
            const result = await createUser(enteredEmail, enteredPassword);
            if (result) {
               // authenticate the user
               const res = await signIn('credentials', {
                  redirect: false,
                  email: enteredEmail,
                  password: enteredPassword,
               });
               if (!res.ok) {
                  setErrorMessage(res.error);
                  setLoading(false);
                  trackEvent(SG_EVENT_NAMES.USER_SIGN_UP_FAILED, { email: enteredEmail, error: res.error });
               }
               trackEvent(SG_EVENT_NAMES.USER_SIGN_UP, { email: enteredEmail });

               const { redirect, ...remainingQuery } = router.query;

               if (router.query.ai_assistant) {
                  localStorage.setItem('ai_assistant', 'true');
               }

               const destination = !redirect || redirect == 'undefined' ? '/dashboard' : redirect;

               // Rebuild the URL with the remaining query parameters
               const queryString = new URLSearchParams(remainingQuery).toString();
               const fullDestination = queryString ? `${destination}?${queryString}` : destination;

               router.push(fullDestination);
            }
         } catch (error) {
            captureException(error);
            setErrorMessage(error.message);
            setLoading(false);
            trackEvent(SG_EVENT_NAMES.USER_SIGN_UP_FAILED, { email: enteredEmail, error: error.message });
         }
      }
   }

   async function handleGoogleLogin() {
      console.log('we are at google');
      try {
         const res = await signIn('google', {
            redirect: false,
         });

      } catch (error) {
         console.log(error);
      }
   }
   return (
      <div className={styles.wrapper}>
         <div className={styles.formContainer}>
            {/*form header*/}
            <div className={styles.formHeader}>
               <h1>{login ? 'Welcome Back!' : 'Create an Account'}</h1>
               <Link
                  className={styles.switchForm}
                  href={{
                     pathname: login ? '/sign-up' : '/login',
                     query: {
                        redirect: redirect || '/',
                        ...router.query,
                     },
                  }}
               >
                  {login ? "Don't have an account? Sign up" : 'Already have an account? sign in'}
               </Link>
            </div>

            <Divider />
            <form onSubmit={handleFormSubmit}>
               {login ? (
                  <LoginFormComponent emailRef={emailRef} passwordRef={passwordRef} loading={loading} />
               ) : (
                  <SignupFormComponent
                     emailRef={emailRef}
                     passwordRef={passwordRef}
                     confirmPasswordRef={confirmPasswordRef}
                     loading={loading}
                  />
               )}

               <Button type="default" block size="large" className={styles.googleButton} onClick={handleGoogleLogin}>
                  <Space className={styles.googleButtonInner}>
                     <Image src="/images/google.png" alt="google" width={16} height={16} />
                     {login ? 'Sign In' : 'Sign Up'} with Google
                  </Space>
               </Button>
            </form>
            {errorMessage && (
               <Alert data-cy="alert" severity="error">
                  {errorMessage}
               </Alert>
            )}
         </div>
         {/* left side of the page - carusel section */}
         <div className={styles.sideSection}>
            <GradinetContainer>
               <div className={styles.inner}>
                  <div className={styles.logo}>
                     <Image src="/images/logo-light.svg" alt="logo" width={150} height={70} />
                     <h1>A Whole New World Built For Presentations</h1>
                     <p>Better quality, bigger options, faster outcomes and sleeker designs!</p>
                  </div>
                  <Carousel autoplay>
                     {clientReviews.data?.map((review) => (
                        <div key={review.id} className={styles.review}>
                           <h4>{review.review}</h4>
                           <div className={styles.clientInfo}>
                              {review?.picture !== null && (
                                 <Image src={review?.picture} alt="client" width={50} height={50} />
                              )}
                              <div>
                                 <h5>{review.name}</h5>
                                 <p>{review.job}</p>
                              </div>
                           </div>
                        </div>
                     ))}
                  </Carousel>
               </div>
            </GradinetContainer>
         </div>
      </div>
   );
}

function LoginFormComponent({ emailRef, passwordRef, loading }) {
   return (
      <>
         <TextField
            inputRef={emailRef}
            name="email"
            label="Email"
            variant="outlined"
            required
            type={'email'}
            placeholder={'email@company.com'}
            size={'small'}
            sx={{
               width: '100%',
            }}
         />
         <PasswordInput inputRef={passwordRef} size={'small'} />

         <Link href="/forgot-password">
            <Typography variant="body1" color={'primary'} className={styles.switchForm}>
               Forgot Password?
            </Typography>
         </Link>
         <Button type="primary" size="large" htmlType="submit" disabled={loading} block>
            Sign In
            {loading && (
               <CircularProgress
                  size={24}
                  sx={{
                     color: theme.palette.primary.main,
                     position: 'absolute',
                  }}
               />
            )}
         </Button>
      </>
   );
}

function SignupFormComponent({ emailRef, passwordRef, confirmPasswordRef, loading }) {
   return (
      <>
         <TextField
            inputRef={emailRef}
            name="email"
            label="Email"
            variant="outlined"
            required
            type={'email'}
            placeholder={'email@company.com'}
            size={'small'}
            sx={{
               width: '100%',
            }}
         />
         <PasswordInput inputRef={passwordRef} size={'small'} />
         <PasswordInput
            id="outlined-basic"
            inputRef={confirmPasswordRef}
            size={'small'}
            label={'Confirm Password'}
            placeholder={'confirm password'}
            name={'confirmPassword'}
         />

         <Button type="primary" size="large" htmlType="submit" disabled={loading} block>
            Sign Up
            {loading && (
               <CircularProgress
                  size={24}
                  sx={{
                     color: theme.palette.primary.main,
                     position: 'absolute',
                  }}
               />
            )}
         </Button>
      </>
   );
}

export default LoginForm;
