import styles from "./GradinetContainer.module.css";

function GradinetContainer(props) {
   return (
      <div className={styles.container}>
         {props.children}
         <div className={styles.gradOne} />
      </div>
   );
}

export default GradinetContainer;
