import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { useState } from 'react';

export default function PasswordInput(props) {
   const { inputRef, id, size, label, placeholder, name, error, helperText, ...rest } = props;
   const [showPassword, setShowPassword] = useState(false);
   const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
   };
   const handleMouseDownPassword = (event) => {
      event.preventDefault();
   };
   return (
      <FormControl
         sx={{
            width: '100%',
         }}
         size={size || 'medium'}
         variant="outlined"
         required
         error={error}
      >
         <InputLabel htmlFor="standard-adornment-password">{label || 'Password'}</InputLabel>
         <OutlinedInput
            inputRef={inputRef}
            id={id}
            type={showPassword ? 'text' : 'password'}
            name={name || 'password'}
            placeholder={placeholder || 'password'}
            endAdornment={
               <InputAdornment position="end">
                  <IconButton
                     aria-label="toggle password visibility"
                     onClick={handleClickShowPassword}
                     onMouseDown={handleMouseDownPassword}
                     edge="end"
                  >
                     {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
               </InputAdornment>
            }
            label={label || 'Password'}
         />
         <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
   );
}
